import { render, staticRenderFns } from "./CampaignPresets.vue?vue&type=template&id=fce1a94a&scoped=true&"
import script from "./CampaignPresets.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignPresets.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignPresets.vue?vue&type=style&index=0&id=fce1a94a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fce1a94a",
  null
  
)

export default component.exports