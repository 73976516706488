import { render, staticRenderFns } from "./CampaignEditor.vue?vue&type=template&id=26ccbfaa&scoped=true&"
import script from "./CampaignEditor.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignEditor.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignEditor.vue?vue&type=style&index=0&id=26ccbfaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ccbfaa",
  null
  
)

export default component.exports