import { Campaign, CampaignStatus, CampaignType, DynamicCampaignType } from '@/modules/campaigns/models/campaign.model';
import { CustomTemplate } from '@/modules/templates/custom/models/customTemplate.model';
import { DefaultTemplate } from '@/modules/templates/default/models/defaultTemplate.model';
import { ProductModel, TagInputsByInputType } from '@/core/models/product.model';
import { RequestStatus } from '@/modules/settings/models/accountSettings.model';
import { TagColor } from '@/core/models/colors.model';

const VACANCY_VISITS = 'vacancy visits';
const EMPLOYER_BRAND_AWARENESS = 'employer brand awareness';
const JOB_APPLICATIONS = 'job applications';
const FAST_APPLY_FORM = 'fast apply form';
const CUSTOM = 'custom';
const MAXIMISE_CONVERSIONS = 'MAXIMISE_CONVERSIONS';
const MAXIMISE_CLICKS = 'MAXIMISE_CLICKS';
const MAXIMISE_SALES = 'MAXIMISE_SALES';
const LEAD_GENERATION = 'LEAD_GENERATION';

function productConfig(): ProductConfig[] {
  return [
    {
      productModel: 'campaign',
      store: require('@/modules/campaigns/store/campaigns.store').default,
      selectedProductModel: require('@/modules/campaigns/store/campaigns.store').default.selectedCampaign,
    },
    {
      productModel: 'customTemplate',
      store: require('@/modules/templates/custom/store/customTemplates.store').default,
      selectedProductModel: require('@/modules/templates/custom/store/customTemplates.store').default.selectedCustomTemplate,
      homePageRoute: 'custom-templates',
    },
    {
      productModel: 'defaultTemplate',
      store: require('@/modules/templates/default/store/defaultTemplates.store').default,
      selectedProductModel: require('@/modules/templates/default/store/defaultTemplates.store').default.selectedDefaultTemplate,
      homePageRoute: 'default-templates',
    },
    {
      productModel: 'jobList',
      store: require('@/modules/JobLibrary/store/jobLibrary.store'),
    },
  ];
}

export interface ProductConfig {
  productModel: ProductModel;
  store: any;
  selectedProductModel?: Campaign | DefaultTemplate | CustomTemplate;
  homePageRoute?: string;
}

function getProductConfig(productModel: ProductModel): ProductConfig {
  return productConfig().find((item) => item.productModel === productModel)!;
}

const formInputsByProductType = {
  [VACANCY_VISITS]: ['jobProfiles', 'locations', 'startEndDate', 'landingPage', 'poNumber', 'referenceNumber', 'language'],
  [EMPLOYER_BRAND_AWARENESS]: ['functionGroup', 'locations', 'startEndDate', 'landingPage', 'poNumber', 'referenceNumber', 'language'],
  [JOB_APPLICATIONS]: ['jobProfiles', 'locations', 'startEndDate', 'landingPage', 'poNumber', 'referenceNumber', 'language'],
  [FAST_APPLY_FORM]: ['jobProfiles', 'locations', 'startEndDate', 'poNumber', 'referenceNumber', 'language'],
  [CUSTOM]: ['jobProfiles', 'functionGroup', 'campaignGoal', 'locations', 'startEndDate', 'landingPage', 'poNumber', 'referenceNumber', 'language'],
};

const tagColorPalette: Record<TagColor, string> = {
  default: 'var(--tag-default)',
  success: 'var(--tag-success)',
  gray: 'var(--tag-default)',
  green: 'var(--tag-green)',
  blue: 'var(--tag-blue)',
  red: 'var(--tag-red)',
  purple: 'var(--tag-purple)',
  orange: 'var(--tag-orange)',
};

const tagColorByStatus: Record<CampaignStatus, TagColor> = {
  'active': 'success',
  'finished': 'blue',
  'draft': 'gray',
  'paused': 'purple',
  'stopped': 'gray',
  'failed': 'red',
  'to approve': 'orange',
};

const tagColorByRequestStatus: Record<RequestStatus, TagColor> = {
  pending: 'gray',
  accepted: 'success',
  rejected: 'red',
};

const tagColorByType: Record<string, TagColor> = {
  [EMPLOYER_BRAND_AWARENESS]: 'purple',
  [VACANCY_VISITS]: 'blue',
  [JOB_APPLICATIONS]: 'orange',
  [FAST_APPLY_FORM]: 'green',
  [CUSTOM]: 'default',
};

// All associated sorting options for the product types
function sortProduct(product: any, sortOption: string) {
  switch (sortOption) {
    case 'title' : return product.sort(sortTitle);
    case 'runtime' : return product.sort(sortRuntime);
    case 'budget' : return product.sort(sortBudget);
    case 'type' : return product.sort(sortType);
    case 'functionGroup' : return product.sort(sortFunctionGroup);
  }
}

function sortTitle(a: Campaign | CustomTemplate, b: Campaign | CustomTemplate) {
  let comparison = 0;
  if (a.title! > b.title!) {
    comparison = 1;
  } else if (a.title! < b.title!) {
    comparison = -1;
  }
  return comparison;
}

function sortRuntime(a: CustomTemplate | DefaultTemplate, b: CustomTemplate | DefaultTemplate) {
  let comparison = 0;
  if (a.runtime > b.runtime) {
    comparison = 1;
  } else if (a.runtime < b.runtime) {
    comparison = -1;
  }
  return comparison;
}

function sortBudget(a: Campaign | CustomTemplate | DefaultTemplate, b: Campaign | CustomTemplate | DefaultTemplate) {
  let comparison = 0;
  if (a.customTotalGrossBudget! > b.customTotalGrossBudget!) {
    comparison = 1;
  } else if (a.customTotalGrossBudget! < b.customTotalGrossBudget!) {
    comparison = -1;
  }
  return comparison;
}

function sortType(a: Campaign | CustomTemplate, b: Campaign | CustomTemplate) {
  let comparison = 0;
  if (a.type > b.type) {
    comparison = 1;
  } else if (a.type < b.type) {
    comparison = -1;
  }
  return comparison;
}

function sortFunctionGroup(a: DefaultTemplate, b: DefaultTemplate) {
  let comparison = 0;
  if (a.functionGroup! > b.functionGroup!) {
    comparison = 1;
  } else if (a.functionGroup! < b.functionGroup!) {
    comparison = -1;
  }
  return comparison;  
}

function convertTypeForDatabase(type: CampaignType | DynamicCampaignType) {
  const typeMap: Record<string, any> = {
    [JOB_APPLICATIONS]: 'conversions',
    [EMPLOYER_BRAND_AWARENESS]: 'awareness',
    [VACANCY_VISITS]: 'highlight',
    [FAST_APPLY_FORM]: 'leadform',
    [CUSTOM]: 'custom',
  };

  return typeMap[type];
}

const tagOptionsByInputType: TagInputsByInputType = {
  ads: [ 'jobTitle', 'city', 'company' ],
  leadform: {
    intro: {
      headline: [ 'jobTitle', 'city', 'company' ],
      description: [ 'jobTitle', 'description', 'city', 'company' ],
    },
    completion: {
      headline: [ 'jobTitle', 'city', 'company' ],
      description: [ 'jobTitle', 'description', 'city', 'company' ],
      link: [ 'url' ],
    },
  }
}

function convertTypeForFrontend(type: string) {
  const typeMap: Record<string, string> = {
    conversions: JOB_APPLICATIONS,
    awareness: EMPLOYER_BRAND_AWARENESS,
    highlight: VACANCY_VISITS,
    leadform: FAST_APPLY_FORM,
    custom: CUSTOM,
  };

  return typeMap[type];
}

export {
  convertTypeForDatabase,
  convertTypeForFrontend,
  formInputsByProductType,
  tagColorByStatus,
  tagColorByRequestStatus,
  tagColorByType,
  tagColorPalette,
  getProductConfig, 
  productConfig, 
  sortProduct,
  VACANCY_VISITS,
  JOB_APPLICATIONS,
  EMPLOYER_BRAND_AWARENESS,
  FAST_APPLY_FORM,
  CUSTOM,
  LEAD_GENERATION,
  MAXIMISE_CONVERSIONS,
  MAXIMISE_CLICKS,
  MAXIMISE_SALES,
  tagOptionsByInputType,
};
